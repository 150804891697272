import Layout from "layout";
import React, { FC, ReactElement } from "react";
import { PortFolioEntry } from "../api/sdk";
import {
  BasePageContext,
  TemplateHeadProps,
  TemplatePageProps,
} from "utils/types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SEO from "components/SEO";
import Button from "components/Button";
import LeftCarouselArrow from "components/LeftCarouselArrow";
import RightCarouselArrow from "components/RightCarouselArrow";

export interface PortfolioDetailPageContext extends BasePageContext {
  portfolioEntry: PortFolioEntry;
}

export default (
  props: TemplatePageProps<PortfolioDetailPageContext>
): ReactElement => {
  const { portfolioEntry, appData } = props.pageContext;

  return (
    <Layout appData={appData}>
      <div className="container--small portfolio-single spaced-50">
        <h1 className="with-divider">
          <span>{portfolioEntry.name}</span>
        </h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            gap: "2rem",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: "4rem",
          }}
        >
          <Carousel
            swipeable
            emulateTouch
            showStatus={false}
            showThumbs={false}
            renderArrowPrev={LeftCarouselArrow}
            renderArrowNext={RightCarouselArrow}
          >
            {portfolioEntry.images?.map((image, i) => (
              <div key={i}>
                <img src={image} alt={`${portfolioEntry.name}  image ${i}`} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </Layout>
  );
};

export const Head = (props: TemplateHeadProps<PortfolioDetailPageContext>) => {
  const { portfolioEntry } = props.pageContext;

  return (
    <SEO
      title="Portfolio"
      ogMeta={{
        title: `${portfolioEntry.name} | Hillary Taylor`,
        description: `${portfolioEntry.name} by Hillary Taylor`,
        image: portfolioEntry.thumbnail,
      }}
    ></SEO>
  );
};
