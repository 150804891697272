import React, { PropsWithChildren } from "react";
import { Link } from "gatsby";

const arrowIcon = require("images/arrow-blue.svg").default;

export default (onClick: () => void, hasNext: boolean) => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          right: -100,
          top: 0,
          bottom: 0,
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {hasNext ? (
          <div
            style={{
              width: 60,
              height: 80,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            onClick={onClick}
          >
            <img
              src={arrowIcon}
              style={{
                userSelect: "none",
                transform: "rotateY(180deg)",
              }}
              alt={`Preview`}
            />
          </div>
        ) : (
          <div
            style={{
              width: 60,
              height: 80,
              display: "flex",
              alignItems: "center",
            }}
            onClick={onClick}
          >
            <img
              src={arrowIcon}
              style={{
                opacity: 0.4,
                userSelect: "none",
                transform: "rotateY(180deg)",
              }}
              alt={`Preview`}
            />
          </div>
        )}
      </div>
    </>
  );
};
